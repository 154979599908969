export default {
  'Assigned managers': 'Gerentes asignados',
  'Add manager': 'Agregar gerente',
  'Model': 'Modelo',
  'SN': 'NS',
  'Assignee': 'Asignado',
  'Device': 'Dispositivo',
  'Select manager': 'Seleccionar gerente',
  'Assign manager': 'Asignar gerente',
  'Location view': 'Vista de ubicación',
  'No managers assigned': 'No hay gerentes asignados',
  'Managers currently assigned to this device':
    'Gerentes actualmente asignados a este dispositivo.',
  'Provide a name to easily identify the device':
    'Proporcione un nombre para identificar fácilmente el dispositivo.',
  'Unexpected error during updating device profile':
    'Error inesperado durante la actualización del perfil del dispositivo',
  'Provide the official address of the device': 'Proporcione la dirección oficial del dispositivo.',
  'Confirm removal': 'Confirmar eliminación',
  'wouldYouLikeToRemoveMemberAsAssignedManagerOfDevice':
    '¿Deseas eliminar a {memberName} como gerente asignado de {deviceName}?',
  'Photos of the terminal’s area to show its location':
    'Fotos del área del terminal para mostrar su ubicación.',
  'Assign a manager to this device': 'Asignar un gerente a este dispositivo.',
  'usersHaveBeenSuccessfullyAssigned':
    '{count} se ha asignado correctamente al dispositivo. | {count} se han asignado correctamente al dispositivo.',
  'No managers available to assign': 'No hay gerentes disponibles para asignar',
  'There are currently no managers you can assign. Check back later or update the manager list':
    'Actualmente no hay gerentes que pueda asignar. Vuelva más tarde o actualice la lista de gerentes.',
  'Managers will appear here once they’ve been assigned':
    'Los gerentes aparecerán aquí una vez que hayan sido asignados.',
}
