export default {
  'Assigned managers': 'Assigned managers',
  'Add manager': 'Add manager',
  'Model': 'Model',
  'SN': 'SN',
  'Assignee': 'Assignee',
  'Device': 'Device',
  'Select manager': 'Select manager',
  'Assign manager': 'Assign manager',
  'Location view': 'Location view',
  'No managers assigned': 'No managers assigned',
  'Managers currently assigned to this device': 'Managers currently assigned to this device.',
  'Provide a name to easily identify the device': 'Provide a name to easily identify the device.',
  'Unexpected error during updating device profile':
    'Unexpected error during updating device profile',
  'Provide the official address of the device': 'Provide the official address of the device.',
  'Confirm removal': 'Confirm Removal',
  'wouldYouLikeToRemoveMemberAsAssignedManagerOfDevice':
    'Would you like to remove {memberName} as an assigned manager of the {deviceName}?',
  'Photos of the terminal’s area to show its location':
    'Photos of the terminal’s area to show its location.',
  'Assign a manager to this device': 'Assign a manager to this device.',
  'usersHaveBeenSuccessfullyAssigned':
    '{count} has been successfully assigned to the device. | {count} have been successfully assigned to the device.',
  'No managers available to assign': 'No managers available to assign',
  'There are currently no managers you can assign. Check back later or update the manager list':
    'There are currently no managers you can assign. Check back later or update the manager list.',
  'Managers will appear here once they’ve been assigned':
    'Managers will appear here once they’ve been assigned.',
}
