export default {
  'Beta': 'Beta',
  'Personal account': 'Personal account',
  'welcomeTo': 'Welcome to {name}!',
  'youHaveBeenInvitedBy': 'You have been invited by {name}.',
  'pleaseCompleteFormToAction': 'Please complete the form below to {action} your account.',
  'logInTo': 'log in to',

  'welcomeToOrgJoin': '@:welcomeTo @:pleaseCompleteFormToAction',
  'welcomeToOrgJoinLogin': '@:welcomeTo',
  'welcomeToOrgInvitation': 'Welcome! @:youHaveBeenInvitedBy @:pleaseCompleteFormToAction',

  'Invitation URL': 'Invitation URL',

  'Integrations': 'Integrations',
  'Address book': 'Address book',
  'Addresses that are associated with your account':
    'Addresses that are associated with your account.',
  'Make changes to your address': 'Make changes to your address.',
  'Provide your address details': 'Provide your address details.',

  'Your address has been removed': 'Your address has been removed.',
  'Remove your address': 'Remove your address',
  'Are you sure you want to remove this address from your profile?':
    'Are you sure you want to remove this address from your profile?',
  'Remove address': 'Remove address',
  'Add your address': 'Add your address',
  'Enter your address in the fields provided below':
    'Enter your address in the fields provided below.',
  'Select your preferred address from the options below':
    'Select your preferred address from the options below.',
  'organizationHasARequirement':
    '{name} has a requirement, please click continue to complete the requirement.',
  'Personal details': 'Personal details',
  'Add new address': 'Add new address',
  'Organization settings': 'Organization settings',
  'byJoiningOrgYouAgreeToPartnersLegal': 'By joining {name} you are accepting their {legal}.',
  'keyoDisclaimer':
    'Keyo, Inc. is a third-party processor facilitating your transaction with {name}. Read our {privacy} here.',
  'Delete': 'Delete',
  'Organization information updated': 'Organization information updated',
  'Failed to update organization information': 'Failed to update organization information',

  'Default card': 'Default card',
  'Delete card': 'Delete card',
  'onceYouTapDeleteCardWillBeDeletedPermanently':
    'Once you tap {action}, your card will be permanently deleted from your account.',
  'Your card has been deleted successfully': 'Your card has been deleted successfully.',
  'membersJoinedOverThePastMonth':
    'Over the past month, {count} member joined the organization. | Over the past month, {count} members joined the organization.',
  'Average identification time': 'Average identification time',
  'Successful identifications': 'Successful identifications',
  'Scans': 'Scans',
  'vs yesterday': 'vs yesterday',
  'Successful scans per application': 'Successful scans per application',
  'Total scans': 'Total scans',
  'Identification app': 'Identification app',
  'Enrollment app': 'Enrollment app',
  'Number of scans': 'Number of scans',
  'Members in organization': 'Members in organization',
  'Daily': 'Daily',
  'Monthly': 'Monthly',
  'Yearly': 'Yearly',
  'You don’t have any cards in your wallet': 'You don’t have any cards in your wallet.',
  'Seconds': 'Seconds',
  'Feature flags': 'Feature flags',
  'Enable beta languages': 'Enable beta languages',
  'Enable new dashboard': 'Enable new dashboard',
  'Enable activity logs': 'Enable activity logs',
  'Missing a feature?': 'Missing a feature?',
  'We value your suggestions!': 'We value your suggestions!',
  'Let us know, and we’ll add it to our roadmap!': 'Let us know, and we’ll add it to our roadmap!',
  'We’d love your help in taking Keyo to the next level':
    'We’d love your help in taking Keyo to the next level.',
  'Invite a new member to the organization!': 'Invite a new member to the organization!',
  'You can do it with dashboard!': 'You can do it with dashboard!',
  'Learn more about new Integrations': 'Learn more about new Integrations.',
  'New feature!': 'New feature!',
  'Learn how to manage your organization’s profile':
    'Learn how to manage your organization’s profile.',
  'Want to help members enroll their palm? Learn more!':
    'Want to help members enroll their palm? Learn more!',
  'Your organizations': 'Your organizations',
  'NV': 'NV',
  'No value': 'No value',
  'No organizations': 'No organizations',
  'You haven’t joined any organizations yet': 'You haven’t joined any organizations yet.',
  'Metrics': 'Metrics',
  'Show more': 'Show more',
  'Show less': 'Show less',
  'Guides & Tools': 'Guides & Tools',
  'Account overview': 'Account overview',
  'emulationActionUserSuccess': 'Emulated "{action}" for {user} successfully.',
  'Emulation unexpected error': 'Emulation unexpected error.',
  'Action': 'Action',
  'Perform': 'Perform',
  'Enroll': 'Enroll',
  'Identify': 'Identify',
  'System activity emulator': 'System activity emulator',
  'Emulate failed palm identification': 'Emulate failed palm identification',
  'Identification failed': 'Identification failed',
  'Emulated Failed Identification successfully': 'Emulated "Failed Identification" successfully.',
  'ofTotalPages': 'of {totalPages}',
  'Invitations': 'Invitations',
  'youHavePendingInvitationsPleaseCheckThem':
    'You have {amount} pending invitation-review it now! | You have {amount} pending invitations—please check them!',
  'Remove': 'Remove',
  'Transfer ownership': 'Transfer ownership',
  'View device': 'View device',
  'Your changes have been saved successfully': 'Your changes have been saved successfully.',
  'Role has been changed successfully': 'Role has been changed successfully',
  'Browse file': 'Browse file',
  'No results found': 'No results found',
  'It looks like nothing matches your search': 'It looks like nothing matches your search.',
  'Export CSV': 'Export CSV',
  'byProceedingAgreeToTerms':
    'By proceeding, you agree to Keyo’s {terms}, {privacy} and {messaging}',
  'common': {
    helpUsEnhance: 'Please, help us enhance our services!',
    createNewOrganization: 'Create new organization',
    orgLegalMessage: 'By joining this organization you agree to these partner’s {legal}.',
  },
  'layouts': {
    keyoLogoWide: 'Keyo wide logo',
    keyoLogoSquare: 'Keyo square logo',
    biometrics: 'Biometrics',
    developers: 'Developers',
    webhookManagement: 'Webhook management',
    systemActivityEmulator: 'Activity simulator',
    apiReference: 'API reference',
    postmanCollection: 'Postman collection',
    developersHub: 'Developers hub',
    settings: 'Settings',
    helpCenter: 'Help center',
    knowledgeHub: 'Knowledge hub',
    logOut: 'Log out',
    toggleSidebar: 'Toggle Sidebar',
    administrator: 'Administrator',
    personal: 'Personal',
    profileSettings: 'Profile settings',
    wallet: 'Wallet',
    dashboard: 'Dashboard',
    preferences: 'Preferences',
  },
  'components': {
    btnResend: {
      resendSecurityCode: 'Resend security code',
      resendSecurityCodeIn: 'Resend security code in {remaining}s',
    },

    actionsDropdown: {
      showMenu: 'Show menu',
      hideMenu: 'Hide menu',
    },
    modals: {
      'videoPlayer': {
        notSupported: 'Your browser does not support the video',
      },
      'createOrganization': {
        title: 'Organization Creation',
        subtitle: 'Please fill in the form below.',
        created: 'Organization has been created',
        failedCreated: 'Failed to create organization',
      },
      'membersPause': {
        title: 'Pause User',
        areYouSure: 'Are you sure you want to pause',
        confirmText:
          'Click ‘confirm’ to continue. Users will receive an email notification of pausing their account.',
        pauseSuccess: 'Member {name} has been paused.',
        pauseError: 'Unexpected error during attempt to pause {name}. Try again later',
      },
      'membersRenew': {
        title: 'Renew User',
        areYouSure: 'Are you sure you want to renew',
        confirmText:
          'Click ‘confirm’ to continue. Users will receive an email notification of their renewed membership.',
        renewSuccess: 'Member {name} has been renewed.',
        renewError: 'Unexpected error during attempt to renew {name}. Please try again later',
      },
      'Leave this window?': 'Leave this window?',
      'leaveWithoutSaving?':
        'Are you sure you want to leave this window? Changes that you make cannot be saved.',
    },
  },
  'modules': {
    apiCreds: {
      noApiCredentials: 'No API credentials',
      keyDescription:
        'The key will allow you to authenticate API requests.\nAlways store your secret key in a safe and secure location.',
      toGetStarted: 'To get started click “Create secret key” to create one.',
      createSecretKey: 'Create secret key',
      secretKey: 'Secret key',
      rotate: 'Rotate',
      credentialsName: 'Credentials name',
      modals: {
        secretRotateConfirm: {
          title: 'Rotate secret key?',
          description: 'Are you sure you want to rotate the secret key?',
        },
        secretCreated: {
          description:
            'Make sure you save it - you won’t be able to access it again. If you lose this secret key,  you’ll need to generate a new one.',
        },
      },
    },
    auth: {
      common: {
        expiredLink:
          'The link is expired or invalid. Please check your email for further instructions or request a new one',
        didNotGetCode: "Didn't get a security code?",
        pleaseAddMethod: 'Please add your {method}',
        changeVerificationMethod: 'Change verification method',
        verifyMethod: 'Verify {method}',
        pleaseVerifyMethod: 'To help protect your identity, please verify your {method}.',
        resendEmail: 'Resend email',
        unexpectedLoginSessionError: 'Unexpected login session error.',
        completeFormToCreateAccount: 'Please complete the form below to create your account.',
        checkEmail: 'Check your email',
        activationAccountExpiredLink:
          'Your account activation link is no longer valid. Please provide the email associated with your Keyo account to request a new activation link.',
        activationLinkExpired: 'Activation link has expired',
        emailOrPhoneNumberExample: "Example name{'@'}example.com or +1123456789.",
        forgotPassword: 'Forgot password?',
      },
      components: {
        policyInput: {
          readAndAccept: 'I have read and accept',
        },
      },
      modals: {
        activationLinkSuccess: {
          heading: 'Check your {method}',
          methods: {
            phone: 'phone',
            email: 'email',
          },
          description: 'Please activate your account using the message we sent to your {method}.',
        },
      },
      pages: {
        forgotPasswordInstruction: {
          heading: 'Forgot password',
          description: {
            enterEmail: 'To reset your password please enter your email or phone number below.',
            checkMethod:
              'A reset password link has been sent to {method}. Open the link to continue.',
          },
          checkMessages: 'Check your messages',
          didNotReceiveMessage: 'Didn’t receive message?',
          didNotReceiveEmail: 'Didn’t receive email?',
          resendMessage: 'Resend message',
        },
        activationEmail: {
          cannotResendEmail: 'We cannot resend email. Please try again later.',
          confirmationEmailResent: 'A confirmation email has been resent to you!',
          emailSentTo: 'We sent a confirmation email to',
          checkEmail: 'Check your email and click the activation link to continue.',
        },
        activationPassword: {
          heading: 'Create your password',
          description: 'Please complete the form below to activate your account.',

          readOur: 'Read our',
          readAndAccept: 'I have read and accept',
        },
        forgotPasswordSet: {
          createNewPassword: 'Create new password',
          enhanceAccountSecurity: 'Enhance your account security with a strong password',
          youCanNowUseYourNewPassword:
            'You can now use your new password to log in to your account.',
        },
        fullName: {
          reviewHeading: 'Review your full name',
          reviewDescription: 'Please make sure the information below is correct.',
          enterHeading: 'Enter your full name',
          enterDescription: 'Please complete the form below to finish activating your account.',
        },
        kioskActivationResend: {
          resendActivationLink: 'Resend activation link',
        },
        kioskActivationResendSuccess: {
          description: 'A new invite link has been sent to ',
        },
        signIn: {
          description: 'Please complete the form below to log in.',
          descriptionInactive: 'Please enter your email or phone number.',
          wrongCredentials: {
            text: 'Please verify your email or phone and password, ensuring they match the credentials used at sign up. If the issue persists, please',
            contactSupport: 'Contact support.',
          },
        },
        activationVerifyInstruction: {
          verifyItIsYou: 'Verify it’s you',
          pleaseSelectPreferredMethodForVerifying:
            'Please select your preferred method for verifying your account. Using the selected method, you will be able to log in to your account.',
          ifYouHaveNotReceivedCode:
            "If you haven't received the code, kindly update your {method} or choose an alternative verification method.",
        },
        activationVerifySet: {
          verifyPhoneNumber: 'Verify phone number',
          verifyEmail: 'Verify email',
          description:
            'A security code will be sent to {recipient}.\n Please enter the 6-digit code below.',
        },
        activationSecureAccount: {
          heading: 'Secure your account',
          description: 'Please create a password to log in and keep your account safe.',
        },
        editMethod: {
          headingEmail: 'Edit email',
          headingPhone: 'Edit phone number',
          descriptionEmail: 'Please update your email below.',
          descriptionPhone: 'Please update your phone number below.',
        },
        secureCode: {
          verifyIdentity: 'Verify your identity',
          securityCodeHasBeenSent:
            'A security code will be sent to {recipient}.\\A Please enter the 6-digit code below',
        },
        signup: {
          description: 'To activate your Keyo Dashboard please complete the form below',
          readAndAccept: 'I have read and accept',
        },
        forgotPasswordExpired: {
          heading: 'Expired link',
          description:
            'The provided password reset link is expired. You can request another one below.',
          tryAgain: 'Try again',
        },
      },
    },
    // demo ui
    admin: {
      noDevices: {
        addDevices: 'add devices',
        displaySoon: 'They will be displayed here soon',
        workingOnIt: 'The Keyo team is working on it!',
      },
      deviceInfo: {
        saveSuccess: 'Device information saved',
        unexpectedError: 'Unexpected error during updating device profile',
      },
      inviteUsers: {
        message: 'Invite your first users!',
      },
      usersListSearch: {
        pauseMembers: 'Pause members',
        renewMembers: 'Renew members',
        removeMembers: 'Remove members',
      },
      inviteMembersToYourOrganization: 'Invite members to your organization',
      enterEmail: 'Enter email',
      invitationSent: 'Invitation has been sent to {email}',
      // widget labels
      invite: 'Invite',
      letsBuild: "Let's build something amazing!",
      scanningOnWave: 'Scanning palm on a biometric identification Keyo Wave+ device',
      feedback: 'Have feedback?\nWe would love to learn more!',
      inviteMemberTitle: 'Do you want to invite a new member?',
      inviteMemberDescription: 'You can do it with the Dashboard!',
      userCheckMarkIcon: 'User with a check mark above',
      confirmOwnershipTransfer: 'Confirm ownership transfer',
      confirmOwnershipTransferDesc:
        'A security code will be sent to {method}. Enter the security code and tap Confirm to transfer organization ownership to {nextOwner}.',
      securityCode: 'Security Code',
      enterSecurityCode:
        'Please enter the 6-digit code sent to {email} to confirm the ownership transfer.',
      resendSecurityCode: 'Re-send security code',
      learnMoreAboutKeyo: 'Learn more about Keyo’s admin Dashboard',
      membersInYourOrganization: 'members in your organization',
      organizationDashboard: '{name} Dashboard',
      dashboardDescription:
        'Welcome to your organization’s Dashboard! From here you can access developer tools, purchase devices, invite admins, developers, and members, and more!',
    },
    webhooks: {
      title: 'Webhooks',
      description:
        "Webhooks are a way that one system can notify a different, completely separate system when certain events occur. More specifically, a webhook is an API endpoint that you create on your server, with code to process the event-data coming from Keyo's backend.",
      hostedEndpoints: 'Hosted endpoints',
      registeredEndpoints: 'Registered endpoints to listen and handle events.',
      url: 'URL',
      secret: 'Secret',
      created: 'Created',
      set: 'Set',
      notSet: 'Not set',
      active: 'Active',
      disabled: 'Disabled',

      endpointURL: 'Endpoint URL',
    },
    zendesk: {
      signingIn: 'Signing in to Zendesk',
    },
    personal: {
      components: {
        onBoardingProgress: {
          title: 'Onboarding - {percentage}% complete',
        },
        activateAccountWidget: {
          description:
            'Please activate your account using the message we sent to your email or phone number.',
          done: 'Done',
        },
        joinedBannersWidgets: {
          welcomeToOrg: 'Welcome to {name}',
        },
        cardChangeMfaMethod: {
          ifHaveNotReceivedCode:
            "If you haven't received the code, kindly choose an alternative verification method.",
        },
        policiesWidget: {
          heading: 'Your consent is very important to us',
          description:
            "You'll find all your accepted and signed policies here. Simply click on the policy you wish to review.",
        },
        policyCard: {
          acceptanceDate: 'Acceptance date',
        },
        qrScanCard: {
          label: 'enrollment',
          enrollTitle: 'Enrolling your palm is easy!',
          enrollStep1: 'Visit a Keyo Wave+ device for enrollment.',
          enrollStep2: {
            part2: '“Enroll your palm”',
            part3: 'from the menu.',
          },
          enrollStep3: {
            part2: '“Scan QR code”',
            part3: 'and use your mobile device.',
          },
          unlockTitle: 'Unlock the world with a wave!',
          unlockSubtitle:
            'To enroll your palm, please visit one of our Wave+ devices and tap "Scan QR code."',
        },
        invitationCard: {
          actionRequired: 'Action required',
          youAreInvited: 'You are invited!',
          weArePleasedToInformYou:
            'We are pleased to inform you that you have been invited to join {name}',
        },
      },
      modals: {
        noAccessToCamera: {
          heading: 'Please enable access to your camera',
          description: {
            part1:
              'To continue you’ll need to allow camera access in your settings. We have prepared a guide for you',
            part2: 'here.',
          },
        },
        qrScanner: {
          invalidQrMessage:
            'The QR code you scanned is invalid for enrollment. Please try again later.',
        },
      },
      pages: {
        editProfile: 'Edit profile',
        home: {
          keyoLogo: 'Keyo logo',
          greeting: 'Hi, {name}!',
          welcomeDescription: {
            keyoDashboard: 'Keyo Dashboard',
            part1: 'Welcome to your',
            part2: 'Here, you can manage your account, biometrics, and payment methods.',
          },

          transactionsWidget: {
            label: 'Transactions',
          },
          networkWidget: {
            label: 'Your network',
          },
          educationWidget: {
            title: "Why you'll love being a Keyo member",
            description: 'Learn more about being a member.',
          },
          privacyDesignWidget: {
            title: 'How Keyo builds with privacy by design',
          },
          setupWidget: {
            title: 'Review your profile and choose a display name',
          },
        },
        invitations: {
          accepted: 'Accepted',
          declined: 'Declined',
          decline: 'Decline',
          noInvitations: 'You have no invitations',
        },
      },
      // Pinia - Named as store to avoid being tied to a specific store implementation
      store: {
        uploadPhotoSuccess: 'Photo has been updated',
        uploadPhotoError: 'Error while uploading photo',

        deletePhotoError: 'Error while deleting photo',
      },
    },
    account: {
      common: {
        securityCodeSentToPhone:
          'A security code will be sent to {phone}. Please enter the 6-digit code below.',
        securityCodeSentToMethod:
          'A security code will be sent to {method}. Please enter the 6-digit code below.',
        changeEmail: 'Change email',
        changePhoneNumber: 'Change phone number',
      },
      components: {
        phoneAdd: {
          toAddEnterPhoneNumber:
            'To add your phone number, please  enter your phone number in the field provided below.',
        },
        cardAccountDelete: {
          ownerWarning:
            "As the owner of the organization, you have the option to delete your account. However, please note that if you choose to delete your account, you will need to assign someone else as the new owner of the organization. Once your account is deleted, you will no longer have access to the organization's management and administrative functions.",
          generalWarning:
            'If you have experienced an issue with your account and need assistance, please contact us so that we can help you.',
          deletionNote: {
            part1: 'Please note that deleting your account, as stated in the',
            part2: 'will also result in the deletion of your biometric data in accordance with the',
            part3:
              'This action will deactivate the biometric authentication and payment features, meaning that you will no longer be able to use your biometric information to access or make payments on this system.',
          },
          deleteAccountConfirmation: 'Are you sure you want to delete your account?',
        },
      },
      modals: {
        accountBiometricAlreadyExists: {
          description: 'Sorry, you have already added your biometric data to your account.',
        },
        accountBiometricConfirmEnroll: {
          almostDone: "You're almost done!",
          followInstructions: 'Follow the instructions on the Keyo Wave+',
          startEnrollment: 'Start enrollment',
          startEnrollmentQuestion: 'Would you like to start enrollment in:',
          deviceMissingError: 'Device is missing',
          organizationMissingError: 'Organization is missing',
        },
        accountBiometricDelete: {
          heading: 'Delete your biometric',
          description: {
            part1: 'Please enter the security code we sent to your {method} below. Once you tap',
            part3: ', your biometrics will be permanently deleted from Keyo.',
          },
          deleteBiometric: 'Delete biometric',
        },
        accountBiometricDeleteSuccess: {
          heading: 'Biometric deleted',
          description: 'Your biometric data has been deleted based on our',
        },
        accountDeleteSuccess: {
          heading: 'Account deleted',
          description: 'Your account has been deleted based on our',
        },
        accountPasswordChangeSuccess: {
          description: 'You can now use your new password to log in to your account.',
        },
        accountEmailChangeSuccess: {
          description: 'You can now use your new email to log in to your account.',
        },
        accountSecurityMfaConfirm: {
          change2faAuthentication: 'Change two-factor authentication',
          areYouSureYouWantToChange2fa:
            'Are you sure you want to change the default two-factor authentication method to {method}?',
          changeTo: 'Change to {method}',
        },
        accountSecurityMfaSuccess: {
          description:
            'You have changed your default two-factor authentication method to {method}.',
        },
        accountVerifyMethodSuccess: {
          methodHasBeenVerified: 'Your {method} has been successfully verified.',
        },
        accountPhoneChangeSuccess: {
          description: 'You can now use your new phone number to log in to your account.',
        },
        accountPhoneAddSuccess: {
          description: 'You can now use your phone number to log in to your account.',
        },
        accountEmailStep1: {
          toAddEnterEmail:
            'To add your email, please enter your email in the field provided below.',
        },
        accountEmailAddSuccess: {
          description: 'You can now use your email to log in to your account.',
        },
        accountDelete: {
          description: {
            part1: 'Please enter the security code we sent to your {method}. Once you tap',
            part2: 'your account will be deleted as stated in our',
          },
          termsOfUse: 'Terms of Use.',
        },
        accountEmailChangeStep1: {
          description:
            'A security code will be sent to {email}. Please enter the 6-digit code below.',
        },
        accountEmailChangeStep2: {
          heading: 'Add new email',
          description:
            'To update your email address, please enter your new email address in the field provided below.',
          labelNewEmail: 'Enter new email',
        },
        accountEmailChangeStep3: {
          heading: 'Confirm new email',
          description:
            'A security code will be sent to {newEmail}. Please enter the 6-digit code below.',
        },
        accountPasswordChange: {
          heading: 'Change password',
          description:
            'To update your password, please enter your current password in the field provided below.',
          currentPassword: 'Current password',
          newPassword: 'New password',
          confirmNewPassword: 'Confirm new password',
          step1: 'Step 1',
          step2: 'Step 2',
        },
        accountPhoneChangeStep2: {
          description:
            'To update your phone number, please enter your new phone number in the field provided below.',
          labels: {
            enterNewPhone: 'Enter new phone number',
          },
        },
        accountPhoneChangeStep3: {
          heading: 'Confirm new phone number',
          description:
            'A security code will be sent to {newPhone}. Please enter the 6-digit code below.',
        },
      },
      pages: {
        addAddress: 'Add address',
        profileEdit: {
          uploadInfo: 'You can upload a .jpg or .png file of the user up to 2MB.',
        },
        security: {
          passwordTitle: 'Password',
          changeButton: 'Change',
          addButton: 'Add',
          passwordPlaceholder: '*************',
        },
        securityMfa: {
          updateYourMfa: 'You can update your default method for two-factor authentication here.',
          receiveCodeMethod: 'Receive verification code via {method}.',
          verifyMethod:
            'To receive verification code via {method} you need to finish verification.',
        },
      },
    },
    organization: {
      requirements: {
        connectMobileWallet: 'Connect your mobile wallet',
      },
      modals: {
        organizationRequirements: {
          title: 'Organization requirements',
          toJoinOrganizationCompleteRequirements:
            'To join this organization, please complete the following requirements.',
          requirements: {
            paymentProvider: {
              enterPhoneMobileWallet: 'Enter the phone number linked to your mobile wallet.',
              ensurePhoneNumberIsIn: 'Ensure your phone number is on the ',
              networks: '{networks} network. | {networks} networks.',
            },
          },
        },
      },
    },
  },
  'pages': {
    sadAlt: 'sad',
    org: {
      members: {
        renewMember: 'Renew member',
        pauseMember: 'Pause member',
        removeMember: 'Remove member',
        viewProfile: 'View Profile',
        pause: 'Pause',
        renew: 'Renew',
        members: 'Members',
        orgMembers: 'Here are your organization’s members.',
      },
      devices: {
        title: 'Devices',
        description: 'Here are your organization’s devices.',

        generalInformation: 'General information',
        uploadDescription: 'You can upload photos of the terminal’s area to show its location.',

        view: 'View',
        unexpectedError: 'Unexpected error during loading device data',
        photoAdded: 'Location photo added',
        uploadError: 'Error occurred while uploading location photo',
        deleteError: 'Error occurred while deleting location photo',
      },
      generalForm: {
        nameRequired: 'Name is required',
        countryRequired: 'Country is required',
        stateRequired: 'State is required',
        cityRequired: 'City is required',
        zipRequired: 'ZIP is required',
        streetRequired: 'Street is required',
      },
      businessProfileForm: {
        primaryContactFirstNameLabel: 'Primary Contact (First Name)',
        primaryContactLastNameLabel: 'Primary Contact (Last Name)',
        primaryEmailLabel: 'Primary Email',
      },
      billingAddressForm: {
        countryLabel: 'Country',
        stateLabel: 'State',
        stateRegionLabel: 'State/Region',
        cityLabel: 'City',
        zipLabel: 'ZIP',
        streetLabel: 'Street',
      },
      uploadFile: {
        text: 'Click to upload',
        uploadIcon: 'upload icon',
        alt2: 'cross icon',
      },
    },
    notFoundOrNoAccess: {
      message: 'There is no such page or no access to see it',
    },
    pageNotFound: {
      message: 'Something’s Missing.',
    },
  },
  'utils': {
    validations: {
      required: {
        default: 'This field is required',
      },
      letters: {
        default: 'Can contain only letters',
      },
      email: {
        default:
          "Please make sure you provide a correctly formatted email address (e.g., example{'@'}example.com)",
      },
      phone: {
        default: 'Invalid number.',
        possiblePhone: 'Should be international format starting with +.',
      },
      emailOrPhone: {
        default: 'Please enter email or phone number',
        formatPhone:
          "Please enter a valid phone number in the format '+11234567890'. Up to 15 digits allowed.",
        validEmailOrPhone:
          "Please enter a valid phone number in the format '+11234567890' or provide a correctly formatted email address (e.g., example{'@'}example.com)",
      },
      max: {
        default: 'Max length is {length}',
      },
      min: {
        default: 'Min length is {length}',
      },
      match: {
        default: 'Should match the {name} field.',
      },
      confirm: {
        default: 'Please confirm your {name}',
      },
      password: {
        default: 'Please enter your password',
        weakPassword: 'Password is weak.',
      },
      name: {
        default: 'Allowed only letters, hyphen, apostrophe, dot',
      },
      url: {
        default: 'Invalid URL.',
      },
      mfaCode: {
        default: 'Should be 6-digit code.',
        shouldBeArray: 'Should be array.',
      },
    },
  },
}
